var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notify-table-container"},[_c('CCard',{staticClass:"notify-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,_vm.dateFormat)))])]}},{key:"event",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingBadge',{attrs:{"type":"notification-event","text":item.event}})],1)]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingBadge',{attrs:{"type":"notification-type","text":item.type}})],1)]}},{key:"actived_flg",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.actived_flg)?_c('CIcon',{staticClass:"publish-icon",attrs:{"name":"cil-check"}}):_vm._e()],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"buttons"},[_c('div',[(_vm.isAllowedUpdating)?_c('CButton',{on:{"click":function($event){return _vm.edit(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})]):_vm._e(),(_vm.isAllowedDelete && item.title)?_c('CButton',{on:{"click":function($event){return _vm.openModal(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-del-user.svg"),"alt":""}})]):_vm._e()],1)])]}}])})],1),_c('CModal',{attrs:{"id":"deleteModal","show":_vm.warningModal,"centered":true},on:{"update:show":function($event){_vm.warningModal=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('CButtonClose',{on:{"click":function($event){_vm.warningModal = false}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"cancel-btn",attrs:{"color":"dark","variant":"outline"},on:{"click":function($event){_vm.warningModal = false}}},[_vm._v(_vm._s(_vm.$t('NOTIFICATION_LIST_TABLE_COMPONENT_MODAL_BUTTON_CANCEL')))]),_c('CButton',{staticClass:"delete-btn",on:{"click":function($event){return _vm.del(_vm.id)}}},[_vm._v(_vm._s(_vm.$t('NOTIFICATION_LIST_TABLE_COMPONENT_MODAL_BUTTON_DELETE')))])]},proxy:true}])},[_c('div',{},[_c('div',{staticClass:"pb-4 tit-dele"},[_c('p',[_vm._v(_vm._s(_vm.$t('NOTIFICATION_LIST_TABLE_COMPONENT_MODAL_TITLE')))])]),_c('div',{staticClass:"text-modal",domProps:{"innerHTML":_vm._s(_vm.$t('NOTIFICATION_LIST_TABLE_COMPONENT_MODAL_CONTENT', { name: _vm.nameDelete }))}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }