<template>
  <div class="search-user-asset-header-container">
    <ValidationObserver ref="form">
      <CRow>
        <CCol md="3" sm="12">
          <ValidationProvider name="SEARCH_NOTIFICATION_HEADER_COMPONENT_KEYWORD_LABEL" v-slot="{ errors }" rules="max:100">
            <CInput
              :label="$t('SEARCH_NOTIFICATION_HEADER_COMPONENT_KEYWORD_LABEL')"
              type="text"
              v-model="searchData.keyword"
              class="form-group"
              @keyup.enter="search()"
            />
            <span class="error-msg-wrap">
              <span class="error-msg">{{ errors[0] }}</span>
            </span>
          </ValidationProvider>
        </CCol>
        <!-- <CCol md="3" sm="12">
          <div role="group" class="form-group form-group">
            <label class>{{ $t('SEARCH_NOTIFICATION_HEADER_COMPONENT_EVENT_LABEL') }}</label>
            <select v-model="searchData.event" class="form-control">
              <option selected value>{{ $t('SEARCH_NOTIFICATION_HEADER_COMPONENT_EVENT_LABEL_ALL') }}</option>
              <option v-for="item in eventList" :key="item.value" :value="item.value">{{ item.label }}</option>
            </select>
          </div>
        </CCol>

        <CCol md="3" sm="12">
          <div role="group" class="form-group form-group">
            <label class>{{ $t('SEARCH_NOTIFICATION_HEADER_COMPONENT_TYPE_LABEL') }}</label>
            <select v-model="searchData.type" class="form-control">
              <option value>{{ $t('SEARCH_NOTIFICATION_HEADER_COMPONENT_TYPE_LABEL_ALL') }}</option>
              <option v-for="item in typeList" :key="item.value" :value="item.value">{{ item.label }}</option>
            </select>
          </div>
        </CCol> -->
      </CRow>
    </ValidationObserver>
    <CRow class="mt-2 form-actions">
      <div class="col-md-3 btn-disabled"></div>
      <div class="col-md-3 btn-disabled"></div>
      <CCol md="3" sm="12">
        <CButton class="btn btn-reset" @click="resetForm()">{{ $t('SEARCH_NOTIFICATION_HEADER_COMPONENT_RESET_BUTTON') }}</CButton>
      </CCol>

      <CCol md="3" sm="12">
        <CButton class="btn btn-search" @click="search()">{{ $t('SEARCH_NOTIFICATION_HEADER_COMPONENT_SEARCH_BUTTON') }}</CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import moment from 'moment';
import { Constants } from '@/constants';
import DateRangePicker from 'vue2-daterange-picker';
import stringHelper from '@/utils/string-helper';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import dateTimeHelper from '@/utils/datetime-helper';

export default {
  name: 'SearchUserAssetHeader',
  components: {},
  data() {
    return {
      searchData: sessionStorageHelper.getData(sessionStorageHelper.KEYS.FilterNotifications) || this.getDefaultValues(),
      eventList: [],
      typeList: [],
      keyword: '',
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
  },
  async mounted() {
    this.search();
    this.typeList = this.$store.state.notification.notificationType;
    this.eventList = this.$store.state.notification.notificationEvent;
  },
  methods: {
    getDefaultValues() {
      return {
        keyword: '',
        event: '',
        type: '',
      };
    },
    resetForm() {
      this.searchData = this.getDefaultValues();
      this.search();
    },
    async search() {
      sessionStorageHelper.setData(sessionStorageHelper.KEYS.FilterNotifications, this.searchData);
      this.onSearch(this.searchData);
    },
  },
};
</script>

<style lang="scss">
.search-user-asset-header-container {
  margin-bottom: 35px;
  .form-search {
    margin-bottom: 5px;
  }

  .vue-daterange-picker {
    width: 100%;
    .reportrange-text {
    }
    .daterangepicker {
      position: absolute;
      top: 35px;
      left: 0;
      transform: unset;
      padding: 0;
      z-index: 99;
      &::after,
      &::before {
        left: 80px;
        right: unset;
      }
      .calendars-container {
        flex-wrap: wrap;
      }
    }
    .fa.fa-calendar {
      display: none;
    }
  }

  .form-actions {
    .btn {
      border: none;
      border-radius: 32px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      border: 1px solid #1a2958;
      border-radius: 17.5px;
      background-color: #1a2958;
      width: 100%;
      display: block;

      &.btn-search {
      }

      &.btn-reset,
      &.btn-download-csv {
        color: #1a2958;
        background-color: #fff;
      }
    }
  }
}
</style>
