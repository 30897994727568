<template>
  <div class="notify-table-container">
    <CCard class="notify-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template #created_at="{item}">
          <td>{{ item.created_at | moment(dateFormat) }}</td>
        </template>
        <template #event="{item}">
          <td>
            <StakingBadge type="notification-event" :text="item.event" />
          </td>
        </template>
        <template #type="{item}">
          <td>
            <StakingBadge type="notification-type" :text="item.type" />
          </td>
        </template>
        <template #actived_flg="{item}">
          <td><CIcon v-if="item.actived_flg" class="publish-icon" name="cil-check" /></td>
        </template>
        <template #action="{item}">
          <td class="buttons">
            <div>
              <CButton @click="edit(item)" v-if="isAllowedUpdating">
                <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
              </CButton>
              <CButton @click="openModal(item)" v-if="isAllowedDelete && item.title">
                <img src="@/assets/images/ico-del-user.svg" alt class="hand edit-icon" />
              </CButton>
            </div>
          </td>
        </template>
      </CDataTable>
    </CCard>
    <CModal id="deleteModal" :show.sync="warningModal" :centered="true">
      <template #header>
        <CButtonClose @click="warningModal = false" />
      </template>
      <div class>
        <div class="pb-4 tit-dele">
          <p>{{ $t('NOTIFICATION_LIST_TABLE_COMPONENT_MODAL_TITLE') }}</p>
        </div>
        <div class="text-modal" v-html="$t('NOTIFICATION_LIST_TABLE_COMPONENT_MODAL_CONTENT', { name: nameDelete })"></div>
      </div>
      <template #footer>
        <CButton class="cancel-btn" @click="warningModal = false" color="dark" variant="outline">{{
          $t('NOTIFICATION_LIST_TABLE_COMPONENT_MODAL_BUTTON_CANCEL')
        }}</CButton>
        <CButton @click="del(id)" class="delete-btn">{{ $t('NOTIFICATION_LIST_TABLE_COMPONENT_MODAL_BUTTON_DELETE') }}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { Constants } from '@/constants';
import responseHelper from '@/utils/response-helper';
import endpoints from '@/constants/endpoints';
import StakingBadge from '@/components/StakingBadge';

export default {
  name: 'NotificationListTable',
  components: { StakingBadge },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: 'id',
          label: '#',
        },
        {
          key: 'title',
          label: this.$t('NOTIFICATION_LIST_TABLE_COMPONENT_TITLE_COL'),
          _style: 'min-width: 150px; text-align: left;',
        },
        // {
        //   key: 'event',
        //   label: this.$t('NOTIFICATION_LIST_TABLE_COMPONENT_EVENT_COL'),
        //   _style: 'min-width: 150px; text-align: left;',
        // },
        // {
        //   key: 'type',
        //   label: this.$t('NOTIFICATION_LIST_TABLE_COMPONENT_TYPE_COL'),
        // },
        {
          key: 'actived_flg',
          label: this.$t('NOTIFICATION_LIST_TABLE_COMPONENT_ACTIVE_FLG_COL'),
          _style: 'min-width: 150px; text-align: left;',
        },
        {
          key: 'created_at',
          label: this.$t('NOTIFICATION_LIST_TABLE_COMPONENT_CREATED_AT_COL'),
          _style: 'min-width: 150px; text-align: left;',
        },
        {
          key: 'action',
          label: '',
          _style: 'min-width: 150px;',
        },
      ],
      warningModal: false,
      nameDelete: '',
      name: '',
    };
  },
  props: {
    getNotifications: {
      type: Function,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
    isAllowedDelete: {
      type: Boolean,
      default: false,
    },
    isAllowedUpdating: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {},
  methods: {
    edit(item) {
      this.$router.push(`notification/edit/${item.id}`);
    },
    async del(id) {
      try {
        await this.$http.delete(endpoints.deleteNotification(id));
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('NOTIFICATION_LIST_TABLE_COMPONENT_DELETE_NOTIFY_TITLE_SUCCESSFUL'),
          text: this.$t('NOTIFICATION_LIST_TABLE_COMPONENT_DELETE_SUCCESSFUL'),
        });
        this.warningModal = false;
        this.getNotifications();
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('NOTIFICATION_LIST_TABLE_COMPONENT_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    openModal(value) {
      this.nameDelete = value.title;
      this.id = value.id;
      this.warningModal = true;
    },
    add() {
      this.$router.push('notification/create');
    },
  },
};
</script>

<style lang="scss">
.notify-table-container {
  .notify-list-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }

      .publish-icon {
        color: #5da30b;
      }

      .btn {
        font-size: 14px;
        font-weight: 400;
        // color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .modal-body {
    padding: 1.3rem 1.5rem;
    .tit-dele {
      p {
        font-size: 18px;
      }
    }
  }

  .modal-content {
    width: 85%;
    .modal-header {
      border: none;
      padding: 0 8px;
      display: none;
    }
    .text-modal {
      strong {
        color: #3a4a7f;
        font-weight: 500;
      }
    }
    .modal-footer {
      border: none;
      justify-content: center;
      .delete-btn {
        background-color: #ff5555;
        color: #ffffff;
        width: 150px;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
      }
      .cancel-btn {
        width: 150px;
        border: 2px solid #cbcbcb;
        color: #657187;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}
</style>
