<template>
  <div class="nofify-container">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="9" sm="12">
            <h1>{{ $t('NOTIFICATION_LIST_PAGE_TITLE') }}</h1>
          </CCol>
          <CCol md="3" sm="12">
            <div class="text-right">
              <CButton class="btn btn-create" @click="create">{{ $t('NOTIFICATION_LIST_PAGE_CREATE') }}</CButton>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchNotificationsHeader :onSearch="onSearch" />
        <NotificationListTable
          :limit="limit"
          :activePage="activePage"
          :items="items"
          :total="total"
          :isAllowedUpdating="isAllowedUpdating"
          :isAllowedDelete="isAllowedDelete"
          :getNotifications="getNotifications"
        />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState, mapActions, mapGetters } from 'vuex';
import NotificationListTable from '@/components/Notification/NotificationListTable';
import SearchNotificationsHeader from '@/components/Notification/SearchNotificationsHeader';
import StakingPagination from '@/components/StakingPagination';
import { endpoints, env, Constants } from '@/constants';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import { GET_NOTIFICATION_EVENT, GET_NOTIFICATION_TYPE } from '@/store/actions/notification';

export default {
  name: 'NotificationList',
  components: {
    NotificationListTable,
    StakingPagination,
    SearchNotificationsHeader,
  },
  data() {
    return {
      items: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      isAllowedUpdating: false,
      isAllowedCreating: false,
      isAllowedDelete: false,
      eventList: [],
      typeList: [],
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.checkPermissions();
    await this.getNotifications();
    await this.getNotificationTypeAndEvent();
  },

  methods: {
    ...mapActions(['getNotificationType', 'getNotificationEvent']),
    async getNotificationTypeAndEvent() {
      await this.getNotificationType();
      await this.getNotificationEvent();
    },
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'UPDATE_NOTIFICATION') {
            this.isAllowedUpdating = true;
          }
          if (item.name === 'DELETE_NOTIFICATION') {
            this.isAllowedDelete = true;
          }
          if (item.name === 'CREATE_NOTIFICATION') {
            this.isAllowedCreating = true;
          }
        });
      }
    },
    async getNotifications(data) {
      data = data || {};
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          keyword: this.filterData.keyword,
          event: this.filterData.event,
          type: this.filterData.type,
        };
        const result = await this.$http.get(endpoints.getNotifications, { params });
        this.items = result.data.items || [];
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('NOTIFICATION_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getNotifications();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getNotifications();
    },
    async onSearch(searchData) {
      const data = searchData;
      this.activePage = 1;

      this.getNotifications(data);
    },
    create() {
      this.$router.push('notification/create');
    },
  },
};
</script>
<style lang="scss">
.nofify-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }

      .btn-create {
        display: inline-block;
        background: #3b4b7e;
        border: none;
        border-radius: 32px;
        min-width: 150px;
        height: 32px;
        color: #fff;
        margin: 0;
      }
    }

    .notification {
      color: red;
      margin: 5px 0 20px 0;
      font-size: 13px;
    }
  }
}
</style>
